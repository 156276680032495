import React, { useState, Dispatch, useEffect } from 'react';
import { Issuer, installmentData } from '../../../types/types';
import { connect } from 'react-redux';
import { mostrarError } from '../../../reducer/config-reducer';
import { GenericObject } from '../../../types/types'
import TarjetaDeCredito from './TarjetaDeCredito';
import { proccessPaymentMercadoPago } from '../../../api/purchase';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';

const MercadoPago = ({ cardNumber, installments, mostrarError, issuer, cardNumberHandle }:
  {
    cardNumber: string,
    installments: installmentData[],
    mostrarError: Dispatch<GenericObject | null>,
    issuer: Issuer | null,
    cardNumberHandle: Dispatch<string>
  }) => {

  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    cardNumber: cardNumber,
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
    HOLDER_NAME: '',
    DOCUMENT: '',
    EMAIL: '',
  });
  const [cuotas, setCuotas] = useState(1);
  const [buttonState, setButtonState] = useState({ text: 'PAGAR', enable: true });
  const [modal, setModal] = useState<boolean>(false);
  const [errorMessage, seterrorMessage] = useState<string | null>(null);
  useEffect(() => {
    (window as any).Mercadopago.setPublishableKey('APP_USR-12497abe-d74a-4412-b3ac-3026dd637c69');
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    if(value.length > Number(event.target.getAttribute('max'))){
      return
     }
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggle = () => {
      setModal(!modal);
  }
  const submitHandler = async () => {
    setButtonState({ text: "Procesando...", enable: false });
    const cardNumber = formState.cardNumber;
    (window as any).Mercadopago.getPaymentMethod({ bin: cardNumber }, function (status: any, response: any) {
      if (status === 200) {
        const methods = response;

        let isValid = false;

        for (let i = 0; i < methods.length && !isValid; i++) {
          const config = methods[i].settings;

          for (let j = 0; config && j < config.length && !isValid; j++) {
            isValid = cardNumber.length === config[j].card_number.length &&
              (window as any).Mercadopago.validateBinPattern(cardNumber, config[j]) &&
              (config[j].card_number.validation === "none" || config[j].card_number.validation === "standard");
          }
        }

        if (isValid) {
          const tokenData = {
            cardholderName: formState.HOLDER_NAME,
            cardNumber: formState.cardNumber,
            cardExpiration: formState.expirationMonth + '/' + formState.expirationYear,
            securityCode: formState.securityCode,
            docType: 'DNI',
            docNumber: formState.DOCUMENT,
            issuer: issuer ? issuer.id : null,
            installments: cuotas
          };

          (window as any).Mercadopago.createToken(tokenData, function (status: any, response: any) {
            
            if (status === 200) {
              // llamada al back
              const formData = {
                issuer_id: issuer ? issuer.id : null,
                installments: cuotas,
                token: response.id
              };

              proccessPaymentMercadoPago(formData)
                .then((res) => {
                  if(res.result) {
                    navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`)
                  } else {
                    seterrorMessage(res!.message);
                    toggle();
                    setButtonState({ text: "PAGAR", enable: true });
                  }
                })
                .catch((error) => {
                  seterrorMessage(error!.message);
                  toggle();
                  setButtonState({ text: "PAGAR", enable: true });
                });

            } else {
              console.error('Error creando token:', response.error);

              const mapaerrores = {
                'E301': 'El numero de tarjeta parece tener un problema',
                'E302': 'El código de seguridad no es válido.',
                '325': 'Revise la fecha de expiración',
                '326': 'Revise el año de expiración',
                '316': 'Revise el nombre',
                '324': 'Revise el DNI'
              }

              const errorCode = response.cause[0].code;
              const errorObject = {
                mensajeAMostrar: mapaerrores[errorCode as keyof typeof mapaerrores]
              };
              seterrorMessage(errorObject.mensajeAMostrar);
              toggle();
              //mostrarError(errorObject);
              setButtonState({ text: "PAGAR", enable: true });
            }
          });
        } else {
          seterrorMessage("Tarjeta no válida");
          toggle();
          setButtonState({ text: "PAGAR", enable: true });
        }
      } else {
        console.error('Error obteniendo información de método de pago:', response.error);
        seterrorMessage(response.error);
        toggle();
        setButtonState({ text: "PAGAR", enable: true });
      }
    });
  };


  const validateCardNumber = (currentCardNumber: string): void => {
      cardNumberHandle(currentCardNumber);
  };

  return (
    <>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>ERROR DE PAGO</ModalHeader>
            <ModalBody className='error-handler'>
                <p className='text-center error-message'>{errorMessage}</p>
                <div className='d-flext justify-content-center p-0 mb-2'>
                    <Row className='text-center'>
                        <Col md={12} className='pb-2 pt-2'>
                            <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggle(); }}>CERRAR </Button>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
        <TarjetaDeCredito
        formState={formState}
        handleInputChange={handleInputChange}
        submitHandler={submitHandler}
        installments={installments}
        cuotas={cuotas}
        setCuotas={setCuotas}
        buttonState={buttonState}
        issuer={issuer}
        validateCardNumber={validateCardNumber} />
    </>
    
  );
};

export default connect(
  null,
  { mostrarError }
)(MercadoPago);
